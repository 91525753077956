import numeral, { locales } from 'numeral'
import 'numeral/locales'
import { useI18n } from 'vue-i18n'

const currencyFormats = {
  usd: {locale: 'en', mask: '$0,0.00'},
  eur: {locale: 'pt-pt', mask: '0,0.00 $'},
  brl: {locale: 'pt-br', mask: '$0,0.00'},
};

export const useNumeral = () => {
  const { locale } = useI18n()

  numeral.locale(locale.value)

  const format = (value: string | number, mask: string = '0.0', currencyCode?: keyof typeof currencyFormats) => {
    if (currencyCode) {
      const currency = currencyFormats[currencyCode]
      if (currency) {
        numeral.locale(currencyFormats[currencyCode].locale)
        const output = numeral(value).format(currencyFormats[currencyCode].mask)
        numeral.locale(locale.value)
        return output
      }
    }

    return numeral(value).format(mask)
  }

  const counter = (value: string | number) => {
    const reference = numeral(value).value()

    if (!reference) {
      return value
    }

    if (reference < 1000) {
      return numeral(value).format('0,')
    } else if (reference < 100000) {
      return numeral(value).format('0,')
    } else {
      return numeral(value).format('0.0 a')
    }
  }

  const getCurrency = () => {
    return numeral.localeData().currency
  }

  return {
    format,
    counter,
    getCurrency
  }
}